import { styled, css } from "styled-components";
import theme from "styled-theming";

import { Container as GridContainer } from "style/components/Page";
import {
    BodyL,
    styleHeadingL,
    styleHeadingXL,
} from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Heading = styled.h2`
    ${theme("size", {
        large: css`
            ${styleHeadingXL}
        `,
        medium: css`
            ${styleHeadingL}
        `,
    })};
    grid-column: ${theme("displayOption", {
        Left: "col-start 1 / span 3",
        Right: "col-start 2 / span 3",
    })};
    justify-self: ${theme("displayOption", {
        Left: "flex-start",
        Right: "flex-end",
    })};
    margin-bottom: ${theme("size", {
        large: theme("hasImage", {
            true: "2.625rem",
            false: "2.625rem",
        }),
        medium: theme("hasImage", {
            true: "3.5rem",
            false: "2.1875rem",
        }),
    })};
    margin-top: 0;
    text-align: ${theme("displayOption", {
        Left: "left",
        Right: "right",
    })};
    z-index: 2;

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 8",
            Right: "col-start 5 / span 8",
        })};
        margin-bottom: ${theme("size", {
            large: theme("hasImage", {
                true: "3.5rem",
                false: "3.5rem",
            }),
            medium: theme("hasImage", {
                true: "4.375rem",
                false: "2.625rem",
            }),
        })};
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 6",
            Right: "col-start 7 / span 6",
        })};
        margin-bottom: ${theme("size", {
            large: theme("hasImage", {
                true: "3.5rem",
                false: "0",
            }),
            medium: theme("hasImage", {
                true: "6.125rem",
                false: "1.75rem",
            }),
        })};
    }

    ${MQ.FROM_XL} {
        margin-bottom: ${theme("size", {
            large: theme("hasImage", {
                true: "4.375rem",
                false: "0",
            }),
            medium: theme("hasImage", {
                true: "8.75rem",
                false: "1.75rem",
            }),
        })};
    }
`;

export const Col = styled.div`
    grid-column: col-start 1 / span 4;
    grid-row: 2;
    z-index: 2;

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 5 / span 8",
            Right: "col-start 1 / span 8",
        })};
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 7 / span 5",
            Right: "col-start 2 / span 5",
        })};
        ${theme("size", {
            large: css`
                grid-row: 1;
                ${theme("hasImage", {
                    false: css`
                        padding-top: 4.375rem;
                    `,
                })}
            `,
        })};
        ${theme("hasImage", {
            true: css`
                grid-row: 2;
            `,
        })}
    }

    ${MQ.FROM_XL} {
        ${theme("size", {
            large: theme("hasImage", {
                false: css`
                    padding-top: 7rem;
                `,
            }),
        })};
    }
`;

export const Text = styled(BodyL)`
    margin-bottom: 2.1875rem;
    z-index: 2;
    ${theme("hasImage", {
        true: css`
            filter: var(--shadow-on-image);
        `,
    })};

    ${MQ.FROM_M} {
        margin-bottom: 2.625rem;
    }

    ${MQ.FROM_XL} {
        margin-bottom: 3.5rem;
    }
`;

export const Content = styled(GridContainer)`
    ${columnGap}
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    grid-template-rows: auto 1fr;
    width: 100%;
    z-index: 2;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }

    ${MQ.FROM_L} {
        grid-template-rows: auto;
    }
`;

export const Container = styled.div<ContainerProps>`
    ${theme("hasImage", {
        true: css`
            min-height: 80vh;

            ${MQ.FROM_L} {
                min-height: 100vh;
            }
        `,
    })};
    ${theme("isInEditMode", {
        true: css`
            min-height: auto;

            ${MQ.FROM_L} {
                min-height: auto;
            }
        `,
    })}

    figure {
        z-index: 1;
    }
`;

interface ContainerProps {
    readonly hasImage?: boolean;
    readonly contentHeight?: number;
}
