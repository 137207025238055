import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Button from "components/Button";
import Image from "components/Image";
import Section from "components/Section";
import useIsInEditMode from "hooks/useIsInEditMode";
import generateSrcSet, { commonSourceSets } from "style/generateSrcSet";
import { addEditAttributes } from "utils/episerver";

import { Container, Heading, Text, Col, Content } from "./Promotion.styled";
import PromotionProps from "./PromotionProps";

const Promotion = ({
    identifier,
    theme,
    contentTheme,
    actionTheme,
    heading,
    content,
    displayOption = "Left",
    link = {},
    size = "medium",
    image,
    anchorName,
}: PromotionProps): ReactElement => {
    const isInEditMode = useIsInEditMode();

    // Override some image specific parameters
    if (image && image.url) {
        image.caption = "";
        theme = "black";
        contentTheme = "white";
        actionTheme = "white";

        image.srcSet = generateSrcSet({
            image: image.url,
            focalPoint: image.focalPoint,
            content: commonSourceSets.large,
            maxWidth: image.uploadedWidth,
            maxHeight: image.uploadedHeight,
            format: "jpg",
        });

        image.cover = true;
        image.darken = true;
    }

    return (
        <Section theme={theme} alignItems="center" id={anchorName}>
            <ThemeProvider
                theme={{
                    theme: theme,
                    contentTheme: contentTheme,
                    actionTheme: actionTheme || contentTheme,
                    displayOption: displayOption,
                    hasImage: !!(image && image.url),
                    size: size,
                    isInEditMode: isInEditMode,
                }}
            >
                <Container key={identifier || `PromotionContent`}>
                    {image?.url && <Image {...image} />}
                    <Content>
                        {(isInEditMode || heading) && (
                            <Heading
                                as="h2"
                                {...addEditAttributes("Heading")}
                                dangerouslySetInnerHTML={{
                                    __html: heading || "",
                                }}
                            />
                        )}
                        <Col>
                            {(isInEditMode || content) && (
                                <Text
                                    as="div"
                                    {...addEditAttributes("Content")}
                                >
                                    {content}
                                </Text>
                            )}
                            {(isInEditMode || link) && (
                                <Button
                                    {...link}
                                    actionTheme={actionTheme}
                                    editPropertyName="Link"
                                    size="large"
                                    gtmClickType="cta"
                                />
                            )}
                        </Col>
                    </Content>
                </Container>
            </ThemeProvider>
        </Section>
    );
};

export default React.memo(Promotion);
